<template>
  <div class="rppPlanning">
    <a-modal
      v-model="visibleModalManage"
      class="mod"
      :title="isEdit ? 'Form Edit RPP' : 'Form Create RPP'"
      :width="1200"
      @cancel="toggleModalManage()"
      centered
      :mask-closable="false"
    >
      <form-rpp
        :currentEditData="currentEditData"
        :allRpp="dataTable"
        :subjects="subjects"
        :currentSubject="idMapel"
        :currentLevel="idLevel"
        :levels="levels"
        :weeks="weeks"
        :propData="newData"
        :loadingValidate="formValidate.loading"
        :newFileList="newFileList"
        :fileList="fileList"
        :fileTopik="fileTopik"
        @handle-change="handleChange"
        @validationForm="validationForm"
        @changeFile="changeFile"
        @intiKpAction="intiKpAction"
        @deleteFile="deleteFile"
      />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManage()">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManage"
          @click="formValidate.loading = true"
        >
          {{ isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-md-flex align-items center">
      <a-select @change="changeMapel()" :style="{
        display: isMobile ? 'block' : null
      }" style="min-width: 150px;" :class="!isMobile ? 'mr-3' : 'mb-3'" v-model="idMapel" size="large">
        <a-select-option v-for="subject in subjects" :value="subject.id" :key="subject.id">{{subject.nama}}</a-select-option></a-select>
      <a-select :style="{
        display: isMobile ? 'block' : null
      }" style="min-width: 150px" @change="fetchDataTable()" :class="!isMobile ? 'mr-3' : 'mb-3'" v-model="idLevel" size="large">
        <a-select-option v-for="level in levels" :value="level.id" :key="level.id">{{level.nama}}</a-select-option></a-select>
      <a-select :style="{
        display: isMobile ? 'block' : null
      }" style="min-width: 150px" :class="isMobile ? 'mb-3' : null" v-model="seeBy" size="large">
        <a-select-option value="kd_master">Basic Competence(s)</a-select-option>
        <a-select-option value="topik">Topic(s)</a-select-option>
      </a-select>
      <div class="ml-auto">
        <a-button
          :block="isMobile"
          @click.prevent="toggleModalManage()"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create RPP</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table class="hasan-table-overflow" :data-source="dataTable" :pagination="pagination"
      :columns="columns" :loading="loadingTable" bordered>
        <a-table :data-source="seeBy === 'topik' ? value.topik : value.kd_master" :pagination="false"
          :columns="seeBy === 'topik' ? topicColumns : kdColumns" slot="expandedRowRender" slot-scope="value">
          <div slot="nullStr" slot-scope="value">{{value || '-'}}</div>
        </a-table>
        <div slot="action" slot-scope="value, record">
          <a-button
            class="mr-3 my-2 text-warning border border-warning"
            block
            size="large"
            @click.prevent="toggleModalManage(record)"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            class="mr-3 my-2"
            block
            size="large"
            @click.prevent="openPreview(record)"
          >
            <a-icon type="file-pdf" />Preview in PDF
          </a-button>
          <a-button
            block
            class="text-danger border border-danger my-2"
            size="large"
            @click.prevent="handleDelete(record)"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
    <div v-if="showPrint" :class="isPrint ? ['invisible', 'position-absolute'] : 'd-none' ">
    <!-- <div> -->
      <div id="rppDoc" class="w-100">
        <RPPDoc
        :isPrint="isPrint"
        :overview="{ namaMapel, namaLevel, tahunAjaran: reportConfig.tahunAjaran, kepalaSekolah: reportConfig.kepalaSekolah }"
        :dataRpp="dataPrint"
        :format="{ width: '750px' }"
        @printDone="printDone"
        />
        <!-- height 1037px -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import config from '@/config'
const formRpp = () => import('@/views/Teacher/Planning/New/RPP/Form')
const RPPDoc = () => import('@/components/app/RPP')

// const columns = [
//   {
//     title: 'Class',
//     dataIndex: 'class',
//     key: 'class',
//     scopedSlots: { customRender: 'nama' },
//   },
//   {
//     title: 'Weeks Created',
//     dataIndex: 'progressWeek',
//     key: 'progressWeek',
//     align: 'center',
//     scopedSlots: { customRender: 'progressWeek' },
//   },
// ]

const kdColumns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    width: 90,
  },
  {
    title: 'Basic Competence',
    children: [
      {
        title: 'Knowledge',
        dataIndex: 'nama_pengetahuan',
        key: 'nama_pengetahuan',
        align: 'center',
        scopedSlots: { customRender: 'nullStr' },
        width: 300,
      },
      {
        title: 'Skill',
        dataIndex: 'nama_keterampilan',
        key: 'nama_keterampilan',
        align: 'center',
        scopedSlots: { customRender: 'nullStr' },
        width: 300,
      },
    ],
  },
]

const topicColumns = [
  {
    title: 'Topics',
    children: [
      {
        title: 'Topic',
        dataIndex: 'nama',
        key: 'topic',
        align: 'center',
        scopedSlots: { customRender: 'nullStr' },
        width: 300,
      },
      {
        title: 'SubTopic',
        dataIndex: 'subtopik',
        key: 'subtopic',
        align: 'center',
        scopedSlots: { customRender: 'nullStr' },
        width: 300,
      },
      {
        title: 'Total File',
        dataIndex: 'total_file',
        key: 'total_file',
        align: 'center',
        width: 100,
      },
    ],
  },
]

export default {
  name: 'RPP',
  components: {
    formRpp,
    RPPDoc,
  },
  props: {
    subjects: {
      type: Array,
      required: true,
    },
    levels: {
      type: Array,
    },
    reportConfig: {
      type: Object,
    },
  },
  data() {
    return {
      visibleModalManage: false,
      loadingActionModalManage: false,
      loadingDelete: false,
      loadingTable: false,
      dataTable: [],
      pagination: {},
      isEdit: false,
      newData: {
        id_week: null,
        pendahuluan_kp: {},
        inti_kp: [
          {
            key: 1,
            alokasi_waktu: null,
            deskripsi: null,
          },
        ],
        penutup_kp: {},
        tujuan_pembelajaran: '',
        sumber_belajar: '',
        alat_media: '',
        penilaian_sikap: '',
        penilaian_pengetahuan: '',
        penilaian_keterampilan: '',
      },
      currentEditData: {},
      idMapel: null,
      idLevel: null,
      weeks: [],
      formValidate: {
        loading: false,
        isValidated: false,
        withNotification: true,
      },
      newFileList: [],
      fileList: [],
      pdfOptions: {
        margin: [10, 10, 10, 10],
        filename: 'File',
        image: {
          type: 'jpeg',
          quality: 0.50,
        },
        html2canvas: {
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          // unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      },
      isPrint: false,
      showPrint: false,
      dataPrint: {},
      namaMapel: null,
      namaLevel: null,
      kdColumns,
      seeBy: 'kd_master',
      topicColumns,
      fileTopik: [],
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      if (Array.isArray(column)) {
        this.newData[column[0]][column[1]] = value
      } else {
        this.newData[column] = value
      }
    },
    toggleModalManage(data) { // if there's any data means it's on edit state
      this.visibleModalManage = !this.visibleModalManage
      if (!this.visibleModalManage) {
        if (this.isEdit) {
          this.newData = {
            id_week: null,
            pendahuluan_kp: {},
            inti_kp: [
              {
                key: 1,
                alokasi_waktu: null,
                deskripsi: null,
              },
            ],
            penutup_kp: {},
            tujuan_pembelajaran: '',
            sumber_belajar: '',
            alat_media: '',
            penilaian_sikap: '',
            penilaian_pengetahuan: '',
            penilaian_keterampilan: '',
          }
          this.currentEditData = {}
          this.newFileList = []
          this.fileList = []
          this.isEdit = false
        }
      } else {
        if (data) {
          this.newData = {
            ...this.newData,
            ...data,
          }
          this.currentEditData = data
          this.isEdit = true
          this.fileTopik = []
          data.topik.forEach(topik => {
            this.fileTopik = this.fileTopik.concat(topik.files)
          })
          this.fileList = data.files.map(file => {
            return {
              id: file.id,
              key: file.id,
              deskripsi_lampiran: file.deskripsi_lampiran,
              file: [file],
            }
          })
        } else {
          let findNoRppWeeksId = null
          for (let i = 0; i < this.weeks.length; i++) {
            const dataLoop = this.weeks[i]
            const findData = this.dataTable.find(rpp => rpp.id_week === dataLoop.id)
            if (!findData) {
              findNoRppWeeksId = dataLoop.id
              break
            }
          }
          this.newData = {
            id_week: findNoRppWeeksId,
            pendahuluan_kp: {
              alokasi_waktu: 5,
              deskripsi: '<ol><li>Guru menyiapakan fisik dan psikis siswa dengan menyapa dan memberi salam melalui zoom meeting.</li><li>Guru <strong>memotivasi </strong>belajar dengan memberi contoh-contoh kepada siswa tentang hal-hal yang berkaitan dengan materi</li><li>Guru menyampaikan manfaat dan tujuan pembelajaran serta langkah-langkah pembelajaran yang akan dilaksanakan.</li><li>Guru mengingatkan tentang menjaga kesehatan disaat pandemi dan senantiasa menggunakan masker keketika keluar rumah</li></ol>',
            },
            inti_kp: [
              {
                key: 1,
                alokasi_waktu: null,
                deskripsi: '',
              },
            ],
            penutup_kp: {
              alokasi_waktu: 5,
              deskripsi: '<ol><li>Guru membuat resume dengan bimbingan tentang point-point penting yang muncul dalam kegiatan pembelajaran tentang materi yang baru dilakukan.</li><li>Guru menginformasikan kegiatan pembelajaran pertemuan selanjutnya.</li><li>Guru memberikan motivasi, pesan, dan menutup pembelajaran dengan berdoa.</li></ol>',
            },
            tujuan_pembelajaran: '<p>Melalui proses pembelajaran ini, siswa diharapkan mampu:</p>',
            sumber_belajar: 'Video, Youtube, PPT, Buku Paket',
            alat_media: 'Virtual Learning dengan Zoom meting, Whatsapp group (WAG) antara Guru, Peserta didik dan orang tua peserta didik, serta aplikasi lainnya.',
            penilaian_sikap: '<ol><li><div>Melalui pengamatan tentang :</div><ol><li>disiplin waktu dalam pembelajaran dan mengumpulkan hasil pembelajaran.</li><li>bekerja sama dengan cara aktif berdiskusi selama pembelajaran berlangsung.</li><li>bertanggung jawab dalam melaporkan hasil pembelajaran.</li></ol></li></ol><p>&nbsp;</p><strong>(sesuaikan dengan sikap yang akan dinilai)</strong>',
            penilaian_pengetahuan: '<ol><li>Lembar kerja (LK) peserta didik yang berupa soal pilihan ganda (Link Google Form) yang berkaitan dengan :<ol><li></li></ol></li></ol>',
            penilaian_keterampilan: '',
          }
        }
      }
    },
    handleOkModalManage() {
      if (!this.formValidate.isValidated && this.formValidate.withNotification) {
        return this.$notification.error({
          message: 'Validation Failed',
          description: 'Please check your fields.',
        })
      }
      if (!this.formValidate.isValidated) return
      const content = `${this.isEdit ? 'Are you sure want to edit this RPP?' : 'Are you sure want to create new RPP'}`
      this.$confirm({
        title: 'Warning',
        content: (
          <div>{content}</div>
        ),
        onOk: async () => {
          try {
            this.loadingActionModalManage = true
            const payload = this.isEdit ? {
              id: this.currentEditData.id,
              data: this.newData,
              idMapel: this.idMapel,
              idLevel: this.idLevel,
            } : {
              idMapel: this.idMapel,
              idLevel: this.idLevel,
              data: this.newData,
            }

            if (!this.isEdit) await this.postRPP(payload)
            else await this.editRPP(payload)

            this.loadingActionModalManage = false
            this.toggleModalManage()
            await this.fetchDataTable()
            const description = this.isEdit ? 'RPP has been updated' : 'RPP has been created'
            this.$notification.success({
              message: 'Success',
              description,
            })
            this.newData = {
              id_week: null,
              pendahuluan_kp: {},
              inti_kp: [
                {
                  key: 1,
                  alokasi_waktu: null,
                  deskripsi: null,
                },
              ],
              penutup_kp: {},
              tujuan_pembelajaran: '',
              sumber_belajar: '',
              alat_media: '',
              penilaian_sikap: '',
              penilaian_pengetahuan: '',
              penilaian_keterampilan: '',
            }
            this.newFileList = []
          } catch (err) {
            this.loadingActionModalManage = false
            const description = this.isEdit ? 'RPP has not been updated' : 'RPP has not been created'
            this.$notification.error({
              message: 'Failed',
              description,
            })
            await this.fetchDataTable()
            // this.newFileList = []
          }
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: this.isEdit ? 'Edit Now' : 'Create Now',
        cancelText: 'Cancel',
      })
    },
    async fetchWeek() {
      try {
        const { data } = await this.$store.dispatch('slot/FETCH_WEEKS')
        this.weeks = data
        return new Promise((resolve) => resolve(true))
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchDataTable() {
      try {
        this.loadingTable = true
        const { data } = await this.$store.dispatch('master/FETCH_RPP_BY', { idMapel: this.idMapel, idLevel: this.idLevel })
        this.dataTable = data.dataTable.map(data => {
          let kdMaster = []
          if (data.kd_master) {
            kdMaster = data.kd_master.map(kd => {
              return {
                key: kd.id,
                ...kd,
              }
            })
          }
          let topiks = []
          if (data.topik) {
            topiks = data.topik.map(topik => {
              return {
                key: topik.id,
                total_file: topik.files.length,
                ...topik,
              }
            })
          }
          return {
            ...data,
            kd_master: kdMaster,
            topik: topiks,
            total_topik: topiks.length,
            total_kd: kdMaster.length,
            key: data.id,
            week: data.week.week,
            startWeek: moment(data.week.tanggal_awal, 'YYYY-MM-DD').format('DD MMMM YYYY'),
            endWeek: moment(data.week.tanggal_akhir, 'YYYY-MM-DD').format('DD MMMM YYYY'),
          }
        })
        this.namaMapel = this.subjects.find(subject => subject.id === this.idMapel)?.nama
        this.namaLevel = this.levels.find(level => level.id === this.idLevel)?.nama
        this.pagination.total = data.total
        this.loadingTable = false
        return new Promise((resolve) => resolve(true))
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    handleDelete(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this RPP?</div>
        ),
        onOk: async () => {
          try {
            this.loadingDelete = true
            await this.$store.dispatch('master/DELETE_RPP', {
              id: data.id,
            })
            await this.fetchDataTable()
            this.loadingDelete = false
            this.$notification.success({
              message: 'Success',
              description:
                'RPP has been deleted',
            })
          } catch (err) {
            console.log(err)
            await this.fetchDataTable()
            this.loadingDelete = false
            this.$notification.error({
              message: 'Failed',
              description: 'RPP has not been deleted',
            })
          }
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    async postRPP({ data: newData, idMapel, idLevel }) {
      try {
        const dataRPP = {
          ...newData,
          id_mata_pelajaran: idMapel,
          id_level: idLevel,
        }
        const formData = new FormData()
        const filepath = `RPP/${idMapel}/${idLevel}/`
        formData.append('dataRPP', JSON.stringify(dataRPP))
        formData.append('filepath', filepath)
        const dataLampiran = []
        this.newFileList.forEach((file, i) => {
          // const newFile = {
          //   ...file.file[0],
          //   deskripsi_lampiran: file.deskripsi_lampiran,
          // }
          dataLampiran.push(file.deskripsi_lampiran)
          formData.append('lampiran', file.file[0])
        })
        formData.append('dataLampiran', JSON.stringify(dataLampiran))
        const { data } = await axios.post(`${config.apiUrl}/api/rpp/`, formData, {
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async editRPP({ data: newData, id, idMapel, idLevel }) {
      try {
        const dataRPP = {
          ...newData,
          id_mata_pelajaran: idMapel,
          id_level: idLevel,
        }
        const formData = new FormData()
        const filepath = `RPP/${idMapel}/${idLevel}/`
        formData.append('dataRPP', JSON.stringify(dataRPP))
        formData.append('filepath', filepath)
        const dataLampiran = []
        this.newFileList.forEach((file, i) => {
          // const newFile = {
          //   ...file.file[0],
          //   deskripsi_lampiran: file.deskripsi_lampiran,
          // }
          dataLampiran.push(file.deskripsi_lampiran)
          formData.append('lampiran', file.file[0])
        })
        formData.append('dataLampiran', JSON.stringify(dataLampiran))
        formData.append('oldFile', JSON.stringify(this.fileList))
        await axios.post(`${config.apiUrl}/api/rpp/update/${id}`, formData, {
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    validationForm(isValidated, withNotification = true) {
      this.formValidate = {
        loading: false,
        isValidated,
        withNotification,
      }
      this.handleOkModalManage()
    },
    changeFile(payload) {
      this.newFileList.push({
        ...payload,
        key: this.newFileList.length + 1,
      })
    },
    async changeMapel() {
      try {
        this.$emit('changeMapel', this.idMapel)
        this.idLevel = this.levels[0]?.id
        await this.fetchDataTable()
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    intiKpAction({ action, payload }) {
      if (action === 'new') {
        this.newData.inti_kp = payload
      } else if (action === 'change') {
        const dataInti = this.newData.inti_kp.find(inti => inti.key === payload.key)
        // find data
        for (const key in payload) {
          if (key !== 'key') {
            dataInti[key] = payload[key]
          }
        }
      }
    },
    deleteFile({ type, key }) {
      if (type === 'new') {
        this.newFileList = this.newFileList.filter(file => file.key !== key)
      } else if (type === 'old') {
        this.fileList = this.fileList.filter(file => file.key !== key)
      }
    },
    openPreview(record) {
      // console.log(record)
      this.dataPrint = record
      this.showPrint = true
      setTimeout(() => {
        this.isPrint = true
      }, 2000)
    },
    printDone() {
      this.showPrint = false
      this.dataPrint = {}
      this.isPrint = false
    },
  },
  created() {
    this.fetchWeek()
    this.idMapel = this.subjects[0]?.id
    this.changeMapel()
    // this.fetchDataTable()
    // this.dataTable = [{ key: 1, week: 1 }, { key: 2, week: 2 }]
  },
  computed: {
    isValidateForm() {
      if (this.newData.nama) {
        return false
      }
      return true
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    columns() {
      const columns = [
        {
          title: 'Week',
          dataIndex: 'week',
          key: 'week',
          align: 'center',
          width: 100,
        },
        {
          title: 'Date',
          align: 'center',
          width: 400,
          children: [
            {
              title: 'Start At',
              dataIndex: 'startWeek',
              key: 'startWeek',
              align: 'center',
              width: 200,
            },
            {
              title: 'End At',
              dataIndex: 'endWeek',
              key: 'endWeek',
              align: 'center',
              width: 200,
            },
          ],
        },
        {
          title: 'Total Basic Competence',
          dataIndex: 'total_kd',
          key: 'total',
          // scopedSlots: { customRender: 'action' },
          align: 'center',
          width: 100,
        },
        // {
        //   title: 'Topic Total',
        //   dataIndex: 'total_kd',
        //   key: 'total_kd',
        //   // scopedSlots: { customRender: 'action' },
        //   align: 'center',
        //   width: 300,
        // },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
          width: 300,
        },
      ]

      return columns.map(col => {
        if (col.key !== 'total') return col
        if (this.seeBy === 'topik') {
          return {
            title: 'Total Topic',
            dataIndex: 'total_topik',
            key: 'total',
            // scopedSlots: { customRender: 'action' },
            align: 'center',
            width: 100,
          }
        }

        return col
      })
    },
  },
}
</script>

<style lang="scss">
// .rppPlanning {
//   .hasan-table-overflow {
//     .ant-spin-nested-loading .ant-spin-container .ant-table {
//       overflow-x: auto;
//     }
//   }
// }
</style>
